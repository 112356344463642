.page-my-profile {
  padding: 30px 5% 50px;
}
.profile-content {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-wrap: wrap;
  .left-portion {
    width: 230px;
    border-radius: 2vw;
    @media screen and (max-width: 768px) {
      width: 100%;
    }
  }
  .right-portion {
    width: calc(100% - 260px);
    margin-left: 30px;
    max-width: 100%;
    position: sticky;
    top: 94px;
    // background-color: white;
    border-radius: 2vw;
    @media screen and (max-width: 768px) {
      width: 100%;
      margin: 10px 0px 0px 0px;
    }
  }
}
.filter-layout {
  display: flex;

  flex-direction: row;
  // flex: 5;
  // flex-direction: row;
  @media screen and (max-width: 900px) {
    flex-direction: column;
  }
  .search-field {
    width: 45%;
    @media screen and (max-width: 900px) {
      width: 100%;
    }
  }
  .date-filter {
    margin-left: 25px;
    float: right;
    @media screen and (max-width: 900px) {
      margin-top: 15px;
      margin-left: 0px;
    }
    .btn-margin {
      margin-top: 30px !important;
      @media screen and (max-width: 1199px) {
        margin-top: 10px !important;
      }
    }
  }
}

.collected-box {
  position: sticky;
  top: 94px;
  background-color: #308e99;
  padding: 20px;
  width: 100%;
  z-index: 99999;
  border-radius: 3vw;
  left: -20px;
  margin-bottom: 30px;
  color: white;
}
