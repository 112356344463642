.page-login {
  display: flex;
  align-items: stretch;
  justify-content: space-between;
}
.page-login .left-portion {
  position: sticky;
  height: calc(100vh - 64px);
  top: 64px;
  min-height: 550px;
  width: 50%;
}
@media screen and (max-width: 768px) {
  .page-login .left-portion {
    top: 64px;
    height: calc(100vh - 57px);
  }
}
.page-login .left-portion img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.page-login .right-portion {
  width: 50%;
  padding: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.page-login .right-portion .right-portion-content {
  width: 500px;
  max-width: 100%;
}
.page-login .right-portion h1 {
  font-size: 34px;
  color: var(--secondary);
}
.page-login .right-portion h2 {
  font-size: 20px;
}
.page-login .right-portion .MuiCheckbox-root {
  margin-right: 5px;
}
.page-login .right-portion .otp-cover input {
  text-align: center;
  font-size: 22px;
  padding: 12px 20px;
}
.page-login .right-portion .otp-cover input:not(:last-child) {
  margin-right: 40px !important;
}
.page-login .right-portion .timer span {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  font-size: 20px;
  font-family: "sf_pro_displayregular";
}
.page-login .right-portion .timer span .MuiSvgIcon-root {
  margin-right: 5px;
}
.page-login .right-portion .timer button {
  background: none !important;
  color: var(--primary) !important;
  text-decoration: underline;
  font-family: "sf_pro_displayregular";
}
.page-login .right-portion .timer button span {
  font-size: 16px !important;
  cursor: pointer;
  font-weight: 600;
}
.page-login .signup-pass .MuiFormHelperText-root.Mui-error {
  position: inherit;
  top: auto;
  text-align: right;
}

.single-otp-input input {
  width: 70px !important;
  padding: 14.5px 20px;
  border-radius: 5px;
  font-size: 22px;
  margin-right: 10px;
  border: 1px solid #c4c4c4;
  color: black;
}

.single-otp-input-dialog input {
  width: 55px !important;
  padding: 14.5px 20px;
  border-radius: 5px;
  font-size: 22px;
  margin-right: 10px;
  border: 1px solid #c4c4c4;
  color: black;
}

.MuiFormLabel-root {
  font-weight: 500 !important;
  display: inline-block;
  margin-bottom: 5px;
}

@media screen and (max-width: 992px) {
  .page-login .left-portion {
    display: none;
  }
  .page-login .right-portion {
    width: 100%;
    min-height: calc(100vh - 64px);
    align-items: flex-start;
    padding-top: 100px !important;
  }
}

@media screen and (max-width: 820px) {
  .page-login .right-portion {
    padding: 20px 50px 50px;
  }
  .page-login .right-portion h1 {
    font-size: 28px;
  }
  .page-login .right-portion h2 {
    font-size: 18px;
  }
}
@media screen and (max-width: 576px) {
  .page-login .right-portion {
    padding: 20px;
    min-height: calc(100vh - 57px);
  }
  .page-login .right-portion h1 {
    font-size: 24px;
  }
  .page-login .right-portion h2 {
    font-size: 16px;
  }
  .page-login .right-portion .otp-cover input {
    width: 35px !important;
    height: 35px !important;
    font-size: 22px;
  }
  .page-login .right-portion .otp-cover input:not(:last-child) {
    margin-right: 20px !important;
  }
  .single-otp-input input,
  .single-otp-input-dialog input{
    width: 60px !important;
    padding: 10px 15px;
  }
}

@media screen and (max-width: 450px) {
  .single-otp-input input,
  .single-otp-input-dialog input{
    width: 40px !important;
    padding: 8px 5px;
    font-size: 18px;
  }
}