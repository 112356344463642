.page-cart {
  padding: 30px 5% 50px;
}
.cart-content {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-wrap: wrap;
  .upper-portion {
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    flex-wrap: wrap;
    background: #308e99;
    box-shadow: 0px 2px 60px rgba(48, 142, 153, 0.4);
    border-radius: 3vw;
    padding: 3vw;
    width: 100%;
    margin: 0px auto 50px;
    @media screen and (max-width: 992px) {
      margin-bottom: 40px;
      box-shadow: 0px 2px 40px rgba(48, 142, 153, 0.4);
    }
    @media screen and (max-width: 768px) {
      margin-bottom: 30px;
      box-shadow: 0px 2px 30px rgba(48, 142, 153, 0.4);
    }
    @media screen and (max-width: 576px) {
      margin-bottom: 20px;
      box-shadow: 0px 2px 20px rgba(48, 142, 153, 0.4);
    }
    .left-portion {
      width: 50%;
      max-height: 350px;
      padding-right: 40px;
      border-right: 1px solid rgba(255, 255, 255, 0.6);
      .select-delivery-type {
        background: rgba(255, 255, 255, 0.1);
        border: 1px dashed #ffffff;
        box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05);
        border-radius: 25px;
        padding: 25px 30px;
        color: white;
        width: 100%;
        height: 100%;
        h1 {
          font-size: 30px;
        }
        .MuiFormGroup-root {
          flex-direction: column;
          .MuiFormControlLabel-root {
            margin-top: 10px;
            .MuiTypography-root {
              font-size: 20px;
            }
            .MuiSvgIcon-root {
              color: white;
            }
          }
        }
      }
      @media screen and (max-width: 992px) {
        max-height: auto;
        padding-right: 30px;
        .select-delivery-type{
          padding: 20px 25px;
          h1{
            font-size: 24px;
          }
        }
      }
      @media screen and (max-width: 767px) {
        width: 100%;
        padding-right: 0px;
        border-right: none;
        border-bottom: 1px solid #d3d3d3;
        padding-bottom: 20px;
        .select-delivery-type{
          padding: 20px;
          h1{
            font-size: 20px;
          }
        }
      }
    }
    .price-card {
      background: rgba(255, 255, 255, 0.1);
      border: 1px dashed #ffffff;
      box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05);
      border-radius: 25px;
      padding: 25px 30px;
      color: white;
      width: calc(50% - 40px);
      margin-left: 40px;
      h1 {
        font-size: 30px;
      }
      li {
        padding: 10px 0px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        &:last-child {
          border-top: 1px dashed white;
          margin-top: 15px;
          padding-top: 20px;
        }
      }
      p {
        color: var(--white);
      }
      @media screen and (max-width: 992px) {
        padding: 20px 25px;
        width: calc(50% - 30px);
        margin-left: 30px;
        h1 {
          font-size: 24px;
        }
        .mb-30 {
          margin-bottom: 20px;
        }
        li{
          padding: 4px 0px;
          &:last-child{
            margin-top: 10px;
            padding-top: 15px;
          }
        }
      }
      @media screen and (max-width: 767px) {
        width: 100%;
        margin-left: 0px;
        margin-top: 20px;
        padding: 20px 20px;
        h1 {
          font-size: 20px;
        }
        .mb-30 {
          margin-bottom: 15px;
        }
      }
      .btn {
        border-radius: 8px;
        background-color: white;
        color: #308e99;
        font-weight: bold;
        border: none;
      }
    }
  }
}

.table-cover {
  overflow-x: auto;
  max-width: 100%;
  table {
    width: 100%;
    border-collapse: collapse;
    tr {
      th {
        background-color: rgba(243, 247, 247, 0.7);
        font-size: 20px;
        color: #93a3ba;
        text-align: left;
        padding: 20px;
        white-space: nowrap;
        padding: 20px 10px;
        &:first-child{
          padding-left: 20px;
        }
        &:last-child{
          padding-right: 20px;
        }
        @media screen and (max-width: 992px){
          padding: 15px 8px;
          &:first-child{
            padding-left: 15px;
          }
          &:last-child{
            padding-right: 15px;
          }
          font-size: 16px;
        }
      }
      td {
        padding: 25px 10px;
        vertical-align: top;
        &:first-child{
          padding-left: 20px;
        }
        &:last-child{
          padding-right: 20px;
        }
        .item-img{
          width: 40px;
        }
        @media screen and (max-width: 992px){
          padding: 15px 8px;
          &:first-child{
            padding-left: 15px;
          }
          &:last-child{
            padding-right: 15px;
          }
          .item-img{
            width: 30px;
          }
          .ml-20{
            margin-left: 12px !important;
          }
        }
        h2{
          white-space: nowrap;
        }
        h3{
          word-break: break-all;
        }
        .btn-cover {
          display: flex;
          align-items: stretch;
          justify-content: flex-start;
          background-color: #F3F7F7;
          width: 140px;
          border-radius: 10px;
          span {
            padding: 3px 5px 5px;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 20px;
            flex: 1;
            font-weight: bold;
            @media screen and (max-width: 1200px) {
              font-size: 16px;
            }
          }
          img{
            cursor: pointer;
          }
          @media screen and (max-width: 1200px) {
            width: 120px;
            img{
              width: 30px;
            }
          }
          @media screen and (max-width: 1050px) {
            width: 100px;
            span{
              font-size: 15px !important;
            }
            img{
              width: 25px;
            }
          }
        }
        .del-icon{
          @media screen and (max-width: 1050px) {
            width: 25px;
          }
        }
      }
    }
    .for-mobile{
      display: none;
    }
    @media screen and (max-width: 767px){
      .for-mobile{
        display: block;
        margin-top: 5px;
        .del-icon{
          margin-left: 0px !important;
          width: 20px !important;
        }
      }
      .for-desktop{
        display: none;
      }
    }
  }
}
