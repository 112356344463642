header {
  padding: 8px 5%;
  position: sticky;
  top: 0;
  box-shadow: 0px 0px 3px 2px rgba(0, 0, 0, 0.2);
  background: var(--white);
  border-bottom: 1px solid #d3d3d3;
  z-index: 999;
  height: 64px;
  @media screen and (max-width: 768px) {
    height: 57px;
    padding: 8px 5%;
  }
  .justify-start {
    img {
      // width: 50px;
      max-width: 100px;
      height: 100%;
      cursor: pointer;
      // @media screen and (max-width: 768px) {
      //   width: 40px;
      // }
    }
    h2 {
      display: -webkit-box;
      max-width: 210px;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      @media screen and (max-width: 768px) {
        max-width: 120px;
      }
    }
    h5 {
      display: -webkit-box;
      max-width: 210px;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      font-weight: 400;
      text-align: left;
      color: var(--secondary);
      text-transform: capitalize;
      @media screen and (max-width: 768px) {
        max-width: 180px;
      }
    }
  }
  ul {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    li {
      padding: 5px 20px;
      font-size: 18px;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      @media screen and (max-width: 768px) {
        padding: 4px 15px;
      }
    }
  }
  .justify-end {
    img {
      width: 25px;
      cursor: pointer;
      @media screen and (max-width: 768px) {
        width: 20px;
      }
      @media screen and (max-width: 576px) {
        // width: 16px;
        &.cart-icon {
          width: 20px;
        }
      }
    }
    .profile-img-cover {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      cursor: pointer;
      img {
        width: 35px;
        height: 35px;
        border-radius: 50%;
        object-fit: cover;
        @media screen and (max-width: 768px) {
          width: 30px;
          height: 30px;
        }
        @media screen and (max-width: 576px) {
          width: 25px;
          height: 25px;
        }
      }
    }
  }
  .for-mobile {
    display: none !important;
  }
  @media screen and (max-width: 991px) {
    .for-desktop {
      display: none !important;
    }
    .for-mobile {
      display: flex !important;
    }
  }
}
.for-mobile-menu {
  display: none !important;
  @media screen and (max-width: 991px) {
    display: flex !important;
  }
}
.MuiBadge-badge {
  background-color: var(--primary);
  color: white;
  cursor: default;
}

.MuiMenuItem-root {
  font-size: 18px;
  min-height: 48px !important;
  min-width: 180px;
  @media screen and (max-width: 992px) {
    font-size: 16px;
    min-height: auto !important;
  }
  @media screen and (max-width: 768px) {
    font-size: 14px;
  }
  @media screen and (max-width: 576px) {
    font-size: 12px;
  }
  img {
    width: 25px;
    cursor: pointer;
    @media screen and (max-width: 992px) {
      width: 22px;
    }
    @media screen and (max-width: 768px) {
      width: 20px;
    }
    @media screen and (max-width: 576px) {
      width: 18px;
    }
    &.menu-profile-img {
      width: 25px !important;
      height: 25px !important;
      object-fit: cover;
      border-radius: 50%;
    }
  }
}
