.page-review-order {
  padding: 30px 5%;
}
.review-order-content {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-wrap: wrap;
  .left-portion {
    width: calc(50% - 20px);
    position: sticky;
    top: 94px;
    @media screen and (max-width: 768px) {
      width: 100%;
      position: relative;
      top: auto;
    }
    .uploaded-prescription {
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      flex-wrap: wrap;
      margin: -10px -10px 20px;
      li {
        padding: 10px;
        .single-prescription {
          border: 1px solid #d3d3d3;
          width: 90px;
          height: 110px;
          position: relative;
          @media screen and (min-width: 1600px) {
            width: 100px;
            height: 120px;
          }
          @media screen and (max-width: 768px) {
            width: 60px;
            height: 73px;
          }
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
          .close-icon {
            position: absolute;
            top: -16px;
            right: -12px;
            color: red;
            font-size: 18px;
            cursor: pointer;
          }
        }
      }
    }
  }
  .right-portion {
    width: calc(50% - 20px);
    max-width: 100%;
    @media screen and (max-width: 768px) {
      width: 100%;
    }

    @media screen and (max-width: 768px) {
      width: 100%;
      margin-top: 30px;
      margin-left: 0px;
    }
    .guide-img {
      width: 100%;
    }
    .upload-terms {
      list-style-type: decimal;
      padding-left: 20px;
      li {
        padding: 10px 0px;
      }
    }
  }
}
