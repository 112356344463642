.page-select-delivery {
  padding: 30px 5%;
}
.select-delivery-content {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-wrap: wrap;
  .left-portion {
    width: calc(50% - 20px);
    @media screen and (max-width: 768px) {
      width: 100%;
    }
  }
  .right-portion {
    position: sticky;
    top: 90px;
    width: calc(50% - 20px);
    max-width: 100%;
    @media screen and (max-width: 768px) {
      width: 100%;
      position: relative;
      top: auto;
    }
    @media screen and (max-width: 768px) {
      width: 100%;
      margin-top: 30px;
      margin-left: 0px;
    }
    .uploaded-prescription {
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      flex-wrap: wrap;
      margin: -10px;
      li {
        padding: 10px;
        .single-prescription {
          border: 1px solid #d3d3d3;
          width: 90px;
          height: 110px;
          position: relative;
          @media screen and (min-width: 1600px) {
            width: 100px;
            height: 120px;
          }
          @media screen and (max-width: 768px) {
            width: 60px;
            height: 73px;
          }
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            cursor: pointer;
          }
        }
      }
    }
  }
}

// .page-select-delivery {
//   display: flex;
//   align-items: flex-start;
//   justify-content: space-between;
//   flex-wrap: wrap;
//   .upper-portion {
//     display: flex;
//     align-items: stretch;
//     justify-content: space-between;
//     flex-wrap: wrap;
//     background: #308e99;
//     box-shadow: 0px 2px 60px rgba(48, 142, 153, 0.4);
//     border-radius: 3vw;
//     padding: 3vw;
//     width: 100%;
//     margin: 0px auto 50px;
//     @media screen and (max-width: 992px) {
//       margin-bottom: 40px;
//       box-shadow: 0px 2px 40px rgba(48, 142, 153, 0.4);
//     }
//     @media screen and (max-width: 768px) {
//       margin-bottom: 30px;
//       box-shadow: 0px 2px 30px rgba(48, 142, 153, 0.4);
//     }
//     @media screen and (max-width: 576px) {
//       margin-bottom: 20px;
//       box-shadow: 0px 2px 20px rgba(48, 142, 153, 0.4);
//     }
//     .left-portion {
//       width: 50%;
//       max-height: 350px;
//       padding-right: 40px;
//       border-right: 1px solid rgba(255, 255, 255, 0.6);
//       .select-delivery-type {
//         background: rgba(255, 255, 255, 0.1);
//         border: 1px dashed #ffffff;
//         box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05);
//         border-radius: 25px;
//         padding: 25px 30px;
//         color: white;
//         width: 100%;
//         height: 100%;
//         h1 {
//           font-size: 30px;
//         }
//         .MuiFormGroup-root {
//           flex-direction: column;
//           .MuiFormControlLabel-root {
//             margin-top: 10px;
//             .MuiTypography-root {
//               font-size: 20px;
//             }
//             .MuiSvgIcon-root {
//               color: white;
//             }
//           }
//         }
//       }
//       @media screen and (max-width: 992px) {
//         max-height: auto;
//         padding-right: 30px;
//         .select-delivery-type {
//           padding: 20px 25px;
//           h1 {
//             font-size: 24px;
//           }
//         }
//       }
//       @media screen and (max-width: 767px) {
//         width: 100%;
//         padding-right: 0px;
//         border-right: none;
//         border-bottom: 1px solid #d3d3d3;
//         padding-bottom: 20px;
//         .select-delivery-type {
//           padding: 20px;
//           h1 {
//             font-size: 20px;
//           }
//         }
//       }
//     }
//     .price-card {
//       background: rgba(255, 255, 255, 0.1);
//       border: 1px dashed #ffffff;
//       box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05);
//       border-radius: 25px;
//       padding: 25px 30px;
//       color: white;
//       width: calc(50% - 40px);
//       margin-left: 40px;
//       h1 {
//         font-size: 30px;
//       }
//       li {
//         padding: 10px 0px;
//         display: flex;
//         align-items: center;
//         justify-content: space-between;
//         // &:last-child {
//         // border-top: 1px dashed white;
//         //   margin-top: 15px;
//         //   padding-top: 20px;
//         // }
//       }
//       p {
//         color: var(--white);
//       }
//       .uploaded-prescription {
//         display: flex;
//         align-items: flex-start;
//         justify-content: flex-start;
//         flex-wrap: wrap;
//         margin: -10px;
//         li {
//           padding: 10px;
//           .single-prescription {
//             border: 1px solid #d3d3d3;
//             width: 90px;
//             height: 110px;
//             position: relative;
//             @media screen and (min-width: 1600px) {
//               width: 100px;
//               height: 120px;
//             }
//             @media screen and (max-width: 768px) {
//               width: 60px;
//               height: 73px;
//             }
//             img {
//               width: 100%;
//               height: 100%;
//               object-fit: cover;
//               cursor: pointer;
//             }
//           }
//         }
//       }
//       @media screen and (max-width: 992px) {
//         padding: 20px 25px;
//         width: calc(50% - 30px);
//         margin-left: 30px;
//         h1 {
//           font-size: 24px;
//         }
//         .mb-30 {
//           margin-bottom: 20px;
//         }
//         li {
//           padding: 4px 0px;
//           //   &:last-child {
//           //     margin-top: 10px;
//           //     padding-top: 15px;
//           //   }
//         }
//       }
//       @media screen and (max-width: 767px) {
//         width: 100%;
//         margin-left: 0px;
//         margin-top: 20px;
//         padding: 20px 20px;
//         h1 {
//           font-size: 20px;
//         }
//         .mb-30 {
//           margin-bottom: 15px;
//         }
//       }
//       .btn {
//         border-radius: 8px;
//         background-color: white;
//         color: #308e99;
//         font-weight: bold;
//         border: none;
//       }
//     }
//   }
// }
