.space-between {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.flex-center {
    display: flex;
    align-items: center;
    justify-content: center;
}
.flex-start {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
}
.justify-start {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}
.justify-end {
    display: flex;
    align-items: center;
    justify-content: flex-end !important;
}
.align-flex-end {
    display: flex;
    align-items: flex-end;
    justify-content: center;
}
.flex-wrap {
    flex-wrap: wrap;
}
.nowrap {
    white-space: nowrap;
}
.subtext {
    color: #333 !important;
}
.color-primary {
    color: var(--primary) !important;
}
.color-secondary {
    color: var(--secondary) !important;
}
.color-primary {
    color: var(--primary) !important;
}

.uppercase {
    text-transform: uppercase !important;
}
.capitalize {
    text-transform: capitalize !important;
}
.underline {
    text-decoration: underline !important;
}
.hover-underline {
    &:hover {
        text-decoration: underline !important;
    }
}
.thin {
    font-weight: 300 !important;
}
.regular {
    font-weight: 400 !important;
}
.semi-bold {
    font-weight: 600 !important;
}
.bold {
    font-weight: 700 !important;
}
.extra-bold {
    font-weight: 800 !important;
}
.center {
    text-align: center !important;
}
.right {
    text-align: right !important;
}
.left {
    text-align: left !important;
}
.pointer {
    cursor: pointer !important;
}

.border {
    border: 1px solid var(--border-color);
}
.h-100 {
    height: 100%;
}
.w-100 {
    width: 100% !important;
}

.box-shadow {
    box-shadow: 0px 1px 2px 1px rgba(0, 0, 0, 0.2) !important;
}
.br-10 {
    border-radius: 10px;
}
.p-20 {
    padding: 20px;
}
.mb-5 {
    margin-bottom: 5px;
}
.mb-10 {
    margin-bottom: 10px !important;
}
.mb-15 {
    margin-bottom: 15px;
}
.mb-20 {
    margin-bottom: 20px;
}
.mb-30 {
    margin-bottom: 30px;
}
.mt-0 {
    margin-top: 0px !important;
}
.mt-5 {
    margin-top: 5px;
}
.mt-10 {
    margin-top: 10px;
}
.mt-15 {
    margin-top: 15px;
}
.mt-20 {
    margin-top: 20px !important;
}
.mt-30 {
    margin-top: 30px;
}
.ml-5 {
    margin-left: 5px;
}
.ml-10 {
    margin-left: 10px;
}
.ml-15 {
    margin-left: 15px;
}
.ml-20 {
    margin-left: 20px !important;
}
.ml-30 {
    margin-left: 30px !important;
}
.mr-10 {
    margin-right: 10px !important;
}
.mr-20 {
    margin-right: 20px !important;
}
.mr-30 {
    margin-right: 30px !important;
}
.bg-white-padding-shadow {
    background-color: white;
    padding: 15px 20px;
    box-shadow: 0px 1px 2px 1px rgba(0, 0, 0, 0.2);
}
.hr {
    border-bottom: 1px solid #d3d3d3;
}

.skeuomorphic {
    box-shadow: -5px -5px 8px 0 rgba(255, 255, 255, 0.9), 5px 5px 8px 0 rgba(0, 0, 0, 0.1);
    background-image: linear-gradient(118deg, #e8ebed 10%, #edf0f2 47%);
    border: 1px solid #ffffff6b;
}
.skeuomorphic-with-padding {
    padding: 15px 20px;
    border-radius: 8px;
    border: 1px solid #ffffff6b;
    box-shadow: -5px -5px 8px 0 rgba(255, 255, 255, 0.9), 5px 5px 8px 0 rgba(0, 0, 0, 0.1);
    background-image: linear-gradient(118deg, #e8ebed 10%, #edf0f2 47%);
}

.popup-ui {
    .popup-header {
        padding: 15px 20px;
    }
    .popup-body {
        padding: 20px 20px 30px;
        .btn-cover {
            margin-top: 40px;
            .btn {
                width: calc(50% - 10px);
            }
        }
    }
}
@media screen and (max-width: 576px) {
    input[type="date"]{
        padding-left: 10px !important;
    }
    hr {
        &.mt-15 {
            margin-top: 10px !important;
        }
        &.mt-20 {
            margin-top: 10px !important;
        }
    }
}
@media screen and (min-width: 1500px) {
    textarea {
        font-size: 20px !important;
    }
}
@media screen and (max-width: 420px) {
    input[type="date"] {
        padding: 6.5px 12px 10.5px !important;
    }
}