:root {
  --primary: #308e99;
  // --primary: #53c392;

  --secondary: #152567;
  // --primary: #f07c00;

  --white: #ffffff;
  --white40: #fcfcfd;
  --black: #23262f;
  --subtext: #777e90;
  --yellow: #e8e759;
  --blue: #4444e4;
  --gray: #979797;
  --light-blue: #1b61b6;
  --grey: #8a8a8a;
  --link: #e17582;
  --forgot: #d63e3e;
  --red: red;
  --green: #4aaf39;
  --orange: #fb8c00;
  --info: #25a9e0;
  --light-gray: #eaeaea;
  --warning: #ffc107;
  --status-green: #1bc5bd;
  --status-red: #f64e60;
  --warn: #f44336;
  --light-list: #9a9ea7;
  --light-bg: #faf6f5;
  --light-border: #e6e8ec;
  --light-host: #f4f5f6;
  --light-total: #f4f5f6;
}
