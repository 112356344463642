.page-product-detail {
  padding: 30px 5% 50px;
  .upper-portion {
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    flex-wrap: wrap;
    background: #308e99;
    box-shadow: 0px 2px 60px rgba(48, 142, 153, 0.4);
    border-radius: 3vw;
    padding: 3vw;
    max-width: 100%;
    margin: 0px auto 50px;
    @media screen and (max-width: 992px) {
      margin-bottom: 40px;
      box-shadow: 0px 2px 40px rgba(48, 142, 153, 0.4);
    }
    @media screen and (max-width: 768px) {
      margin-bottom: 30px;
      box-shadow: 0px 2px 30px rgba(48, 142, 153, 0.4);
    }
    @media screen and (max-width: 576px) {
      margin-bottom: 20px;
      box-shadow: 0px 2px 20px rgba(48, 142, 153, 0.4);
    }
    .left-portion {
      display: flex;
      align-items: stretch;
      justify-content: space-between;
      border-right: 1px solid #d3d3d3;
      width: 50%;
      max-height: 350px;
      padding-right: 40px;
      @media screen and (max-width: 992px) {
        max-height: auto;
        padding-right: 30px;
      }
      @media screen and (max-width: 767px) {
        width: 100%;
        padding-right: 0px;
        border-right: none;
        border-bottom: 1px solid #d3d3d3;
        padding-bottom: 20px;
      }
      .product-image {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #ffffff;
        border: 1px dashed #308e99;
        box-shadow: 0px 2px 60px rgba(48, 142, 153, 0.2);
        border-radius: 25px;
        img {
          width: auto;
          height: auto;
          max-width: 80%;
          max-height: 80%;
        }
      }
    }
    .price-card {
      background: rgba(255, 255, 255, 0.1);
      border: 1px dashed #ffffff;
      box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05);
      border-radius: 25px;
      padding: 40px 30px;
      color: white;
      width: calc(50% - 40px);
      margin-left: 40px;
      h1 {
        font-size: 30px;
      }
      li {
        padding: 10px 0px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        @media screen and (max-width: 992px) {
          padding: 4px 0px;
        }
        &:last-child {
          border-top: 1px dashed white;
          margin-top: 15px;
          padding-top: 20px;
          @media screen and (max-width: 992px) {
            margin-top: 10px;
            padding-top: 15px;
          }
        }
      }
      p {
        color: var(--white);
      }
      @media screen and (max-width: 992px) {
        padding: 20px 25px;
        width: calc(50% - 30px);
        margin-left: 30px;
        h1 {
          font-size: 24px;
        }
        .mb-30 {
          margin-bottom: 20px;
        }
      }
      @media screen and (max-width: 767px) {
        width: 100%;
        margin-left: 0px;
        margin-top: 20px;
        padding: 20px 20px;
        h1 {
          font-size: 20px;
        }
        .mb-30 {
          margin-bottom: 15px;
        }
      }
      .btn {
        border-radius: 8px;
        background-color: white;
        color: #308e99;
        font-weight: bold;
        border: none;
      }
      .btn-cover {
        display: flex;
        align-items: stretch;
        justify-content: center;
        .btn {
          width: calc(100% / 3);
          font-size: 30px;
          padding: 2px 20px 5px;
          @media screen and (min-width: 1600px) {
            padding: 4px 20px 10px;
            font-size: 31px;
          }
          @media screen and (max-width: 768px) {
            font-size: 20px;
            padding: 2px 10px 5px;
            min-width: auto;
          }
          &:first-child {
            border-radius: 8px 0px 0px 8px;
          }
          &:last-child {
            border-radius: 0px 8px 8px 0px;
          }
        }
        span {
          padding: 10px 20px;
          border: none;
          outline: none;
          font-weight: bold;
          text-align: center;
          font-size: 20px;
          background-color: #308e99;
          width: calc(100% / 3);
          @media screen and (max-width: 768px) {
            padding: 7px 12px;
            font-size: 14px;
          }
        }
      }
    }
  }
}

.skeu-card {
  background: #ffffff;
  box-shadow: 0px 4px 20px rgba(98, 98, 98, 0.12);
  border-radius: 3vw;
  padding: 30px;
  margin-bottom: 50px;
  @media screen and (max-width: 992px) {
    padding: 25px;
    margin-bottom: 40px;
  }
  @media screen and (max-width: 768px) {
    padding: 20px;
    margin-bottom: 30px;
  }
  @media screen and (max-width: 576px) {
    padding: 15px 20px;
    margin-bottom: 20px;
  }
  p {
    font-size: 20px;
    @media screen and (max-width: 992px) {
      font-size: 18px;
    }
    @media screen and (max-width: 768px) {
      font-size: 16px;
    }
    @media screen and (max-width: 576px) {
      font-size: 15px;
    }
  }
}
