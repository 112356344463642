.page-upload-prescription {
  padding: 30px 5%;

  .upload-prescription-content {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-wrap: wrap;
    @media screen and (max-width: 900px) {
      flex-direction: column-reverse;
    }
    .left-portion {
      width: calc(50% - 20px);
      position: sticky;
      top: 94px;
      @media screen and (max-width: 900px) {
        width: 100%;
        position: relative;
        top: auto;
      }
      .uploaded-prescription {
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        flex-wrap: wrap;
        margin: -10px;
        li {
          padding: 10px;
          .single-prescription {
            border: 1px solid #d3d3d3;
            width: 90px;
            height: 110px;
            position: relative;
            @media screen and (min-width: 1600px) {
              width: 100px;
              height: 120px;
            }
            @media screen and (max-width: 768px) {
              width: 60px;
              height: 73px;
            }
            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
            .close-icon {
              position: absolute;
              top: -16px;
              right: -12px;
              color: red;
              font-size: 18px;
              cursor: pointer;
            }
          }
        }
      }
    }
    .right-portion {
      width: calc(50% - 20px);
      max-width: 100%;
      @media screen and (max-width: 900px) {
        width: 100%;
        margin-bottom: 30px;
        margin-left: 0px;
      }

      .for-mobile {
        display: none;
      }
      .guide-img {
        width: 100%;
        &.for-mobile {
          display: none;
        }
        @media screen and (max-width: 767px) {
          &.for-web {
            display: none;
          }
          &.for-mobile {
            display: block;
          }
        }
      }
      .upload-terms {
        list-style-type: decimal;
        padding-left: 20px;
        li {
          padding: 10px 0px;
        }
      }
    }
  }
  .upload-for-mobile {
    display: none;
    position: fixed;
    width: 100vw;
    bottom: 0px;
    left: 0px;
    // padding: 10px 20px;
    height: 44px;
    z-index: 99;
    background-color: var(--primary);
    color: var(--white);
    .react-file-reader {
      width: 100%;
      position: absolute;
      height: 100%;
      .react-file-reader-button {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
    .btn-primary {
      display: none;
      border-radius: 0px;
      width: 50%;
      height: 100%;
      position: absolute;
      left: 50%;
      border-left: 1px solid white;
      font-size: 19px;
      font-weight: bold;
      padding-top: 2px;
      margin-top: 0px;
    }
    &.continue-enable {
      .react-file-reader {
        width: 50%;
      }
      .btn-primary {
        display: block;
      }
    }
  }
  @media screen and (max-width: 991px) {
    padding-bottom: 70px;

    .upload-for-desktop {
      display: none;
    }
    .for-mobile {
      display: block !important;
    }
    .for-desktop {
      display: none !important;
    }
    .upload-for-mobile {
      display: flex;
    }
  }
}
