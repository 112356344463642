h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 500;
  margin: 0px;
  line-height: 1.3;
}

h1 {
  font-size: 22px;
  @media screen and (min-width: 1600px){
    font-size: 24px;
  }
  @media screen and (max-width: 992px){
    font-size: 20px;
  }
  @media screen and (max-width: 576px){
    font-size: 18px;
  }
  // @media screen and (max-width: 450px){
  //   font-size: 17px;
  // }
}

h2 {
  font-size: 20px;
  @media screen and (min-width: 1600px){
    font-size: 22px;
  }
  @media screen and (max-width: 992px){
    font-size: 18px;
  }
  @media screen and (max-width: 576px){
    font-size: 16px;
  }
  // @media screen and (max-width: 450px){
  //   font-size: 15px;
  // }
}

h3 {
  font-size: 18px;
  @media screen and (min-width: 1600px){
    font-size: 20px;
  }
  @media screen and (max-width: 992px){
    font-size: 16px;
  }
  @media screen and (max-width: 576px){
    font-size: 14px;
  }
  // @media screen and (max-width: 450px){
  //   font-size: 12px;
  // }
}

h4, .MuiTypography-root {
  font-size: 16px;
  @media screen and (min-width: 1600px){
    font-size: 18px;
  }
  @media screen and (max-width: 992px){
    font-size: 14px;
  }
  @media screen and (max-width: 576px){
    font-size: 13px;
  }
  // @media screen and (max-width: 450px){
  //   font-size: 11px;
  // }
}

h5 {
  font-size: 14px;
  @media screen and (min-width: 1600px){
    font-size: 16px;
  }
  @media screen and (max-width: 992px){
    font-size: 13px;
  }
  @media screen and (max-width: 576px){
    font-size: 12px;
  }
  // @media screen and (max-width: 450px){
  //   font-size: 10px;
  // }
}

h6 {
  font-size: 12px;
  @media screen and (min-width: 1600px){
    font-size: 14px;
  }
  @media screen and (max-width: 992px){
    font-size: 11px;
  }
  @media screen and (max-width: 576px){
    font-size: 10px;
  }
  // @media screen and (max-width: 450px){
  //   font-size: 9px;
  // }
}

p {
  font-size: 16px;
  margin-top: 0;
  color: var(--black);
  word-break: break-word;
  @media screen and (min-width: 1600px){
    font-size: 18px;
  }
  @media screen and (max-width: 992px){
    font-size: 15px;
  }
  @media screen and (max-width: 576px){
    font-size: 14px;
  }
  // @media screen and (max-width: 450px){
  //   font-size: 12px;
  // }
}