.product-card {
  border-radius: 4px;
  // background-color: white;
  // overflow: hidden;
  transition: 0.3s;
  cursor: pointer;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  flex-direction: column;
  .product-image {
    padding: 30px 20px;
    width: 100%;
    height: 230px;
    max-width: 100%;
    background: #ffffff;
    box-shadow: 0px 4px 20px rgba(98, 98, 98, 0.12);
    border-radius: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: 0.2s;
    @media screen and (max-width: 991px) {
      height: 190px;
    }
    @media screen and (max-width: 768px) {
      padding: 20px 15px;
      height: 170px;
    }
    @media screen and (max-width: 576px) {
      padding: 15px 10px;
      height: 150px;
    }
    @media screen and (max-width: 400px) {
      height: 140px;
    }
    img {
      width: auto;
      height: auto;
      max-width: 100%;
      max-height: 100%;
    }
  }
  .product-name {
    margin: 20px 0px 10px;
    display: -webkit-box;
    max-width: 100%;
    font-weight: 500;
    color: var(--secondary);
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
  .product-price {
    margin-bottom: 15px;
    color: var(--secondary);
    @media screen and (max-width: 768px) {
      margin-bottom: 10px;
    }
  }
  .btn {
    border-radius: 8px;
    background-color: #308e99;
    color: white;
    font-weight: bold;
    border: none;
  }
  .btn-cover {
    display: flex;
    align-items: stretch;
    justify-content: center;
    .btn {
      width: calc(100% / 3);
      font-size: 30px;
      padding: 2px 20px 5px;
      @media screen and (min-width: 1600px) {
        padding: 4px 20px 10px;
        font-size: 31px;
      }
      @media screen and (max-width: 768px) {
        font-size: 20px;
        padding: 2px 10px 5px;
        min-width: auto;
      }
      &:first-child {
        border-radius: 8px 0px 0px 8px;
      }
      &:last-child {
        border-radius: 0px 8px 8px 0px;
      }
    }
    span {
      padding: 10px 20px;
      border: none;
      outline: none;
      text-align: center;
      font-size: 20px;
      background-color: #f2f2f2;
      // border-top: 1px solid #d3d3d3;
      width: calc(100% / 3);
      @media screen and (max-width: 768px) {
        padding: 7px 12px;
        font-size: 14px;
      }
    }
  }
  @media screen and (min-width: 1025px){
    &:hover {
      .product-image {
        transform: scale(1.05);
      }
    }
  }
}
