body {
  padding: 0px !important;
}
.page-tnc {
  padding: 50px 7%;
}
.page-tnc .tnc-content {
  padding: 20px;
  border-radius: 10px;
  margin-top: 30px;
  background-color: white;
}

@media screen and (max-width: 768px) {
  .page-tnc {
    padding: 30px 5%;
  }
  .page-tnc h1 {
    font-size: 22px;
  }
  .page-tnc .tnc-content {
    margin-top: 20px;
    padding: 15px;
  }
}

@media screen and (max-width: 576px) {
  .page-tnc {
    padding: 20px 5%;
  }
  .page-tnc h1 {
    font-size: 18px;
  }
  .page-tnc .tnc-content {
    margin-top: 15px;
  }
}
