.page-select-address {
  padding: 30px 5%;
}
.select-address-content {
  .MuiFormGroup-root {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: row;
    padding-left: 0px;
    margin: 12px -10px -10px;
    .MuiFormControlLabel-root {
      position: relative;
      margin: 10px;
    }
    .MuiRadio-root {
      opacity: 0;
      position: absolute;
      width: 100%;
      height: 100%;
    }
  }
  .btn-glowing {
    @media screen and (max-width: 1150px) {
      width: 100%;
      margin-top: 15px;
    }
  }
}
