.search-page {
    padding: 30px 5% 50px;
    .flex-start {
        .search-cover {
            flex: 1;
            margin-right: 50px;
            @media screen and (max-width: 992px) {
                margin-right: 20px;
            }
            @media screen and (max-width: 576px) {
                width: 100%;
                margin-right: 0px;
                flex: auto;
            }
            .MuiAutocomplete-input {
                padding: 0px !important;
            }
            .MuiAutocomplete-endAdornment {
                display: none;
            }
            .MuiAutocomplete-root {
                @media screen and (min-width: 577px) {
                    .MuiOutlinedInput-root {
                        padding: 15px;
                    }
                }
                .MuiOutlinedInput-root {
                    border-radius: 5px;
                }
            }
        }
        .btn {
            margin-top: 2px;
            @media screen and (max-width: 576px) {
                width: 100%;
                margin-bottom: 20px;
                margin-top: 0px;
            }
        }
    }
}
