@import "./assets/styles/global.scss";

* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
}
ul,
ol {
  list-style-type: none;
}
img {
  display: table-cell;
}
body {
  font-family: "Barlow", sans-serif;
  padding-right: inherit !important;
  overflow: inherit !important;
  -webkit-tap-highlight-color: transparent;
}
.MuiLink-root {
  color: #333 !important;
  font-weight: 400;
  text-decoration: none !important;
  &:hover {
    color: #5282f0 !important;
  }
}
.MuiAutocomplete-listbox {
  max-height: 280px;
}
.Toastify__toast-container {
  z-index: 99999 !important;
}

.MuiFormControl-root,
.MuiOutlinedInput-root {
  width: 100%;
}
.MuiSelect-select,
.MuiTextField-root {
  background-color: white;
}
.searched-list-item {
  padding: 6px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  &:hover {
    background-color: aliceblue;
  }
}
.MuiFormControlLabel-root.bold .MuiTypography-root {
  font-weight: bold !important;
}
main {
  background-color: #f1f3f6;
  min-height: calc(100vh - 300px);
  &.without-footer {
    min-height: calc(100vh - 64px);
  }
}
@media screen and (max-width: 1200px) {
  .MuiTypography-root {
    font-size: 16px !important;
  }
}
@media screen and (max-width: 1024px) {
  .MuiTypography-root {
    font-size: 14px !important;
  }
}
@media screen and (max-width: 992px) {
  .MuiTypography-root {
    font-size: 13px !important;
  }
}
@media screen and (max-width: 768px) {
  main.without-footer {
    min-height: calc(100vh - 57px);
  }
  .MuiRadio-root {
    padding: 5px 10px !important;
    .MuiSvgIcon-root {
      width: 16px;
      height: 16px;
    }
  }
  .MuiTypography-root {
    font-size: 12px !important;
  }
}
.info-icon {
  font-size: 18px !important;
  margin-top: -2px;
}
@media screen and (max-width: 576px) {
  .MuiRadio-root {
    padding: 2px 10px !important;
  }
  .info-icon {
    font-size: 14px !important;
    margin-top: -1px;
  }

  // Form Fields
  .MuiOutlinedInput-root {
    line-height: 18px;
    height: 35px;
    &.MuiInputBase-multiline {
      height: auto;
    }
    .MuiSelect-select {
      font-size: 12px;
    }
  }
  .MuiTextField-root {
    input {
      font-size: 12px;
      padding: 8.5px 14px;
    }
  }
  .MuiFormLabel-root {
    font-size: 12px !important;
  }
}
.gmnoprint {
  display: none;
}

@-webkit-keyframes glowing {
  0% {
    background-color: #b20000;
    -webkit-box-shadow: 0 0 3px #b20000;
  }
  50% {
    background-color: #ff0000;
    -webkit-box-shadow: 0 0 40px #ff0000;
  }
  100% {
    background-color: #b20000;
    -webkit-box-shadow: 0 0 3px #b20000;
  }
}

@-moz-keyframes glowing {
  0% {
    // background-color: #b20000;
    -moz-box-shadow: 0 0 3px #b20000;
  }
  50% {
    background-color: #ff0000;
    -moz-box-shadow: 0 0 40px #ff0000;
  }
  100% {
    background-color: #b20000;
    -moz-box-shadow: 0 0 3px #b20000;
  }
}

@-o-keyframes glowing {
  0% {
    background-color: #b20000;
    box-shadow: 0 0 3px #b20000;
  }
  50% {
    background-color: #ff0000;
    box-shadow: 0 0 40px #ff0000;
  }
  100% {
    background-color: #b20000;
    box-shadow: 0 0 3px #b20000;
  }
}

@keyframes glowing {
  0% {
    background-color: #b20000;
    box-shadow: 0 0 3px #b20000;
  }
  50% {
    background-color: #ff0000;
    box-shadow: 0 0 40px #ff0000;
  }
  100% {
    background-color: #b20000;
    box-shadow: 0 0 3px #b20000;
  }
}

.infoBox {
  transform: translate(-50%, -100px) !important;
  width: auto !important;
  max-width: 450px !important;
  background: rgba(255, 255, 255, 1);
  border-radius: 10px;
  box-shadow: 0px 1px 3px 2px rgba(0, 0, 0, 0.3);
  @media screen and (max-width: 768px) {
    max-width: 95% !important;
  }
}

.info-box {
  padding: 4px 10px;
  color: rgb(0, 0, 0);
  font-size: 14px;
  font-weight: 600;
  font-family: Roboto, Arial, sans-serif;
  text-align: center;
  white-space: break-spaces;
}

input::-webkit-date-and-time-value {
  text-align: left;
}
@media screen and (max-width: 992px) {
  input[type="date"]::after {
    position: absolute;
    content: "";
    width: 40px;
    height: 26px;
    background-color: #ffffff;
    top: 7px;
    right: 0px;
    pointer-events: none;
  }
}

// .MuiDialog-container{
//   align-items: center;
// }
// @media screen and (max-width: 992px) {
//   .MuiDialog-scrollPaper {
//     align-items: flex-start !important;
//   }
// }
