.banner {
  position: relative;
  max-height: 460px;
  height: 33vw;
  background-color: #fafafa;
  @media screen and (max-width: 1100px) {
    height: 35vw;
  }
  @media screen and (max-width: 900px) {
    height: 42vw;
  }
  @media screen and (max-width: 768px) {
    height: 37vw;
  }
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    display: table-cell;
    border-radius: 0px 0px 4vw 4vw;
  }
  .banner-content {
    position: absolute;
    top: 6%;
    left: 5%;
    @media screen and (max-width: 576px) {
      top: 10%;
    }
    h1 {
      font-size: 3.8vw;
      font-weight: bold;
      text-shadow: 0px 2px 3px rgba(0, 0, 0, 0.2);
      color: var(--white);
      margin-bottom: 10px;
      &.h1-mobile {
        display: none;
      }
      @media screen and (min-width: 1600px) {
        font-size: 60px;
      }
      @media screen and (max-width: 992px) {
        &.h1-mobile {
          display: block;
        }
        &.h1-desktop {
          display: none;
        }
      }
      @media screen and (max-width: 576px) {
        margin-bottom: 5px;
        font-size: 5vw;
      }
    }
    h2 {
      font-size: 3.4vw;
      font-weight: bold;
      text-shadow: 0px 2px 3px rgba(0, 0, 0, 0.2);
      color: var(--white);
      &.h1-mobile {
        display: none;
      }
      @media screen and (min-width: 1600px) {
        font-size: 45px;
      }
      @media screen and (max-width: 992px) {
        &.h1-mobile {
          display: block;
        }
        &.h1-desktop {
          display: none;
        }
      }
      @media screen and (max-width: 576px) {
        margin-bottom: 5px;
        font-size: 4.5vw;
      }
    }
  }
}
.upload-prescription {
  padding: 0px 5%;
  height: 230px;
  text-align: center;
  background-color: #fafafa;
  position: relative;
  @media screen and (min-width: 1600px) {
    height: 240px;
  }
  @media screen and (max-width: 1400px) {
    height: 210px;
  }
  @media screen and (max-width: 1200px) {
    height: 200px;
  }
  @media screen and (max-width: 1100px) {
    height: 190px;
  }
  @media screen and (max-width: 992px) {
    height: 180px;
  }
  @media screen and (max-width: 899px) {
    height: 210px;
  }
  @media screen and (max-width: 850px) {
    height: 200px;
  }
  @media screen and (max-width: 768px) {
    padding: 50px 5%;
    height: 230px;
  }
  @media screen and (max-width: 700px) {
    height: 230px;
  }
  @media screen and (max-width: 650px) {
    height: 220px;
  }
  @media screen and (max-width: 576px) {
    height: 55vw;
  }
  @media screen and (max-width: 520px) {
    height: 58vw;
  }
  @media screen and (max-width: 500px) {
    height: 60vw;
  }
  @media screen and (max-width: 470px) {
    height: 63vw;
  }
  @media screen and (max-width: 450px) {
    height: 65vw;
  }
  @media screen and (max-width: 400px) {
    height: 69vw;
  }
  @media screen and (max-width: 360px) {
    height: 73vw;
  }
  @media screen and (max-width: 340px) {
    height: 250px;
  }
  .upload-prescription-content {
    background: #308e99;
    box-shadow: 0px 2px 60px rgba(48, 142, 153, 0.4);
    border-radius: 3vw;
    padding: 0px 2vw 2.3vw;
    width: 1280px;
    max-width: 100%;
    margin: auto;
    transform: translateY(-50%);
    @media screen and (max-width: 991px) {
      box-shadow: 0px 2px 30px rgba(48, 142, 153, 0.4);
    }
    @media screen and (max-width: 576px) {
      transform: translateY(-25%);
    }
  }
  h1 {
    background: #308e99;
    border: 1px dashed #ffffff;
    border-radius: 1.3vw;
    padding: 1.3vw;
    color: white !important;
    font-weight: bold;
    display: inline-block;
    transform: translateY(-50%);
    margin: auto;
    font-size: 3vw;
    @media screen and (min-width: 1600px) {
      font-size: 35px;
      padding: 25px;
    }
    @media screen and (max-width: 899px) {
      font-size: 4vw;
      padding: 1.5vw;
    }
    @media screen and (max-width: 576px) {
      font-size: 4.5vw;
      max-width: 80%;
    }
  }
  .card {
    background: rgba(255, 255, 255, 0.1);
    border: 1px dashed #ffffff;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05);
    border-radius: 1.4vw;
    padding: 1.5vw 1.5vw 3vw;
    height: 100%;
    text-align: center;
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    flex-direction: column;
    .for-desktop {
      margin: auto;
      @media screen and (max-width: 899px) {
        display: none;
      }
    }
    h2 {
      font-size: 2vw;
      font-weight: bold;
      color: white;
      margin: 30px 0px;
      @media screen and (min-width: 1600px) {
        font-size: 25px;
      }
      @media screen and (max-width: 899px) {
        font-size: 3.3vw;
        margin: 5px 0px 10px;
      }
      @media screen and (max-width: 576px) {
        font-size: 4vw;
      }
      img {
        display: none;
        margin-right: 10px;
        width: 4vw;
        @media screen and (max-width: 899px) {
          display: block;
        }
      }
    }
    .btn {
      @media screen and (min-width: 900px) {
        padding: 15px;
        font-size: 18px;
      }
      @media screen and (max-width: 576px) {
        font-size: 13px;
      }
    }
  }
  .search-cover {
    .MuiAutocomplete-input {
      padding: 0px !important;
      line-height: 1.3;
      height: auto !important;
    }
    .MuiAutocomplete-root {
      box-shadow: 0px 1px 2px 1px rgba(0, 0, 0, 0.1);
      border-radius: 5px;
      background-color: white;
      overflow: hidden;
      @media screen and (min-width: 577px) {
        .MuiOutlinedInput-root {
          padding: 15px;
        }
      }
    }
    .MuiAutocomplete-endAdornment {
      display: none;
    }
    .MuiAutocomplete-root {
      .MuiOutlinedInput-root {
        border-radius: 5px;
      }
    }
  }
}
.top-selling-medicine {
  padding: 10px 5% 60px;
  background-color: #fafafa;
  @media screen and (max-width: 768px) {
    padding: 10px 5% 40px;
  }
  h1 {
    font-size: 3vw;
    color: #152567;
    @media screen and (max-width: 768px) {
      font-size: 5vw;
    }
  }
}
.section-features {
  padding: 20px 5% 90px;
  background-color: white;
  @media screen and (max-width: 768px) {
    padding-bottom: 50px;
  }
  .feature-grid {
    width: 900px;
    max-width: 100%;
    margin: auto;
  }
  .single-feature {
    height: 100%;
    text-align: center;
    border: 1px solid #e2e7ee;
    cursor: pointer;
    border-radius: 8px;
    -webkit-transition: 0.2s;
    transition: 0.2s;
    padding: 30px 20px;
    &:hover {
      border-color: #308e99;
    }
    .img-cover {
      width: 100px;
      margin: auto auto 20px;
      img {
        width: 100%;
      }
    }
    h3 {
      font-size: 16px;
      font-weight: 500;
    }
  }
}

.MuiPagination-ul {
  justify-content: flex-end;
}
