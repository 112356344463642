.btn {
    padding: 12px 15px;
    border-radius: 4px;
    border: 1px solid var(--primary);
    color: var(--primary);
    box-shadow: none;
    transition: 0.1s;
    white-space: nowrap;
    min-width: 70px;
    font-size: 17px;
    font-weight: 500;
    display: flex;
    background-color: var(--white);
    align-items: center;
    justify-content: center;
    user-select: none;
    cursor: pointer;
    font-family: "Barlow", sans-serif;
    @media screen and (min-width: 1600px) {
        padding: 15px 20px;
        font-size: 18px;
    }
    @media screen and (max-width: 768px) {
        font-size: 14px;
        padding: 7px 12px;
    }
    &.small {
        font-size: 14px;
        padding: 7px 12px;
        @media screen and (max-width: 568px) {
            padding: 4px 8px;
            font-size: 12px;
            border-radius: 3px;
            min-width: 60px;
        }
    }
    &.btn-primary {
        color: var(--white);
        background-color: var(--primary);
    }
    &.btn-secondary {
        color: var(--white);
        background-color: var(--secondary);
        border-color: var(--secondary);
    }
    &.btn-secondary-border {
        color: var(--secondary);
        background-color: var(--white);
        border-color: var(--secondary);
    }
    &.btn-white{
        color: #308e99;
        border: none;
    }

    &.btn-simple {
        border: 1px solid #9e9e9e !important;
        color: #444444;
        background-color: #f5f5f5 !important;
    }
    &.btn-danger {
        border: 1px solid #b20000 !important;
        color: white;
        background-color: #b20000 !important;
    }
}
